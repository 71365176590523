







































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getTemplateList } from "@/request/doctor";
@Component({})
export default class Name extends Vue {
  @Prop()
  private visible: any;

  private leftCheckedAll: any = false;
  private rightCheckedAll: any = false;
  private leftSel: any = {};
  private rightSel: any = {};
  private leftData: any = {};
  private rightData: any = {};
  private liangbiaoTem: any = [];
  private jiegou: any = {};
  private data: any = {};

  private cancel() {
    this.$emit("cancel");
  }
  private getNum(item: any) {
    let num = 0;
    this.liangbiaoTem.forEach((element: any) => {
      if (item[element.label]) {
        item[element.label].forEach((ele: any) => {
          if (!ele.hide) {
            num++;
          }
        });
      }
    });
    return num;
  }
  private changeLeft(type: any, index: any) {
    this.leftData[type][index]["checked"] = true;
  }
  private changeRight(type: any, index: any) {
    this.rightData[type][index]["checked"] = true;
  }
  private leftSelAll() {
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    if (this.leftCheckedAll) {
      // 选中全部
      this.liangbiaoTem.forEach((item: any) => {
        if (this.leftData[item.label]) {
          this.leftData[item.label].forEach((ele: any) => {
            if (!ele.hide) {
              ele.checked = true;
              this.leftSel[item.label].push(ele);
            }
          });
        }
      });
    } else {
      // 取消全部选择
      this.liangbiaoTem.forEach((item: any) => {
        this.leftData[item.label].forEach((ele: any) => {
          ele.checked = false;
        });
      });
    }
  }
  private rightSelAll() {
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
    if (this.rightCheckedAll) {
      // 选中全部
      this.liangbiaoTem.forEach((item: any) => {
        if (this.rightData[item.label]) {
          this.rightData[item.label].forEach((ele: any) => {
            if (!ele.hide) {
              ele.checked = true;
              this.rightSel[item.label].push(ele);
            }
          });
        }
      });
    } else {
      // 取消全部选择
      this.liangbiaoTem.forEach((item: any) => {
        this.rightData[item.label].forEach((ele: any) => {
          ele.checked = false;
        });
      });
    }
  }
  private toLeft() {
    this.liangbiaoTem.forEach((item: any) => {
      this.rightSel[item.label].forEach((ele: any) => {
        this.leftData[item.label].forEach((e: any) => {
          if (ele.template_id === e.template_id) {
            // 改变左边数据
            e.checked = false;
            e.hide = false;
            // 改变右边数据
            ele.hide = true;
            ele.checked = false;
          }
        });
      });
    });
    // 清除左边选中的
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
    this.leftCheckedAll = false;
    this.rightCheckedAll = false;
    this.$forceUpdate();
  }
  private toRight() {
    this.liangbiaoTem.forEach((item: any) => {
      if (this.leftSel[item.label] && this.leftSel[item.label].length > 0) {
        this.leftSel[item.label].forEach((ele: any) => {
          this.rightData[item.label].forEach((e: any) => {
            if (ele.template_id === e.template_id) {
              // 改变右边数据
              e.checked = false;
              e.hide = false;
              // 改变左边数据
              ele.hide = true;
              ele.checked = false;
            }
          });
        });
      }
    });
    // 清除左边选中的
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    this.leftCheckedAll = false;
    this.rightCheckedAll = false;
    this.$forceUpdate();
  }
  private getMuban() {
    getTemplateList(this).then((res: any) => {
      this.data = res;
      this.liangbiaoTem.forEach((item: any) => {
        if (this.data[item.label]) {
          this.data[item.label].forEach((ele: any) => {
            ele.checked = false;
          });
        }
      });
      this.leftData = JSON.parse(JSON.stringify(this.data));
      this.rightData = JSON.parse(JSON.stringify(this.data));
      // 右边全部为隐藏状态
      this.liangbiaoTem.forEach((item: any) => {
        if (this.rightData[item.label]) {
          this.rightData[item.label].forEach((ele: any) => {
            ele.hide = true;
          });
        }
      });
    });
  }
  private isShowTitle(items: any) {
    let result = false;
    if (items) {
      items.forEach((ele: any) => {
        if (!ele.hide) {
          result = true;
        }
      });
    }
    return result;
  }
  private sure() {
    let templates: any = [];
    this.liangbiaoTem.forEach((ele: any) => {
      this.rightData[ele.label].forEach((items: any) => {
        if (!items.hide) {
          templates.push({
            template_id: items.template_id,
          });
        }
      });
    });
    if (templates.length === 0) {
      this.$message.warning("请先选择检查项目到右边！");
      return;
    }
    this.$emit("sure", templates);
  }
  private init() {
    this.liangbiaoTem = this.$store.state.configuration.liangbiaoTab;
    this.$store.state.configuration.liangbiaoTab.forEach((ele: any) => {
      this.jiegou[ele.label] = [];
    });
    this.leftData = JSON.parse(JSON.stringify(this.jiegou));
    this.rightData = JSON.parse(JSON.stringify(this.jiegou));
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
  }
  private mounted() {
    this.init();
    this.getMuban();
  }
}
