

























































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import Transfer from "@/components/transfer/transfer.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Transfer,
  },
})
export default class Name extends Vue {
  private ifShowTransfer: any = false;
  private templates: any = [];
  private tuijians: any = [];
  private get currentXuqiu() {
    return this.$store.state.currentXuqiu;
  }
  private getTuijian() {
    this.tuijians = [
      {
        name: "心血管病风险筛查",
        describe:
          "根据美国心脏协会（AHA）心脏健康评估、Framingham心脏研究评估和China-PAR模型进行问卷设计，评估心脏健康现状，预测10年内发生心血管疾病的风险！",
        服务明细: [
          {
            name: "人口学资料",
          },
          {
            name: "实验室检查",
          },
        ],
      },
      {
        name: "高血压风险筛查",
        describe:
          "基于Framingham卒中风险评估模型改良后研发，计算未来10年内脑卒中发病风险及风险等级。",
        服务明细: [
          {
            name: "人口学特征",
          },
          {
            name: "实验室检查",
          },
        ],
      },
    ];
  }
  private makesure() {
    this.$router.push({
      path: "/main/workstation/patient-jiancha-detail",
    });
  }
  private cancelTransfer() {
    this.ifShowTransfer = false;
  }
  private sureTransfer(e: any) {
    this.templates = e;
  }
  private mounted() {
    this.getTuijian();
  }
}
